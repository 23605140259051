@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.quote-text p:first-of-type {
  text-indent: -0.65em;
}

.quote-text p:first-of-type::before {
  display: inline;
  content: open-quote;
}
.quote-text p:last-of-type::after {
  display: inline;
  content: close-quote;
}

[id] {
  @apply scroll-m-10;
}

ol.regulations {
  list-style-type: upper-roman;
  font-weight: 900;
  list-style-position: inside;
}
ol.regulations > li {
  margin-bottom: 1em;
}
ol.regulations ol {
  list-style-type: decimal;
  margin-left: 1em;
  list-style-position: inside;
  font-weight: 400;
}
ol.regulations ol > li {
  margin-bottom: 0;
}
ol.regulations ol ol {
  list-style-type: lower-alpha;
}

@layer components {
  .tick {
    background: url(../images/tick.svg) no-repeat left 0.35em;
    @apply pl-7;
  }

  .funny-focus {
    @apply border-2 border-transparent focus:ring ring-offset-2 ring-black outline-none transition-shadow;
  }

  .funny-focus-reverse {
    @apply border-4 border-transparent focus:border-gray-700 focus:ring ring-offset-2 ring-white outline-none transition-shadow;
  }

  .funny-border {
    @apply rounded-tl-sm rounded-tr-3xl rounded-b-3xl;
  }

  .icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }

  .ol {
    @apply tabular-nums;
  }
  .ol li:nth-child(n + 1):nth-child(-n + 9) {
    padding-left: 1ch;
  }
}

@layer utilities {
  .p-section {
    @apply pt-8 pb-16 md:pt-16 md:pb-20 xl:pt-32 xl:pb-40;
  }

  .shake-shake {
    animation: shake-shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  .ping-ping {
    animation: ping-ping 0.5s ease-in-out;
  }

  .spin-spin {
    animation: spin-spin 1s linear infinite;
  }
}

@keyframes spin-spin {
  0% {
    transform: scale(2) rotate(0deg);
  }
  100% {
    transform: scale(2) rotate(-360deg);
  }
}

@keyframes shake-shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes ping-ping {
  0% {
    transform: scale(3);
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
